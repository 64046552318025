<template>
  <validation-observer ref="obs" v-slot="{ invalid = true }">
    <v-container grid-list-xl>
      <v-toolbar class="blue-grey lighten-4">
        <v-toolbar-title class="pa-5"
          >Contact {{ appName }} Support</v-toolbar-title
        >
      </v-toolbar>
      <form ref="form" @submit.prevent="submit">
        <v-sheet elevation="2" class="pa-4">
          <validated-text-field
            v-model="contact.name"
            :counter="30"
            rules="required"
            label="Name"
          ></validated-text-field>
          <validated-text-field
            v-model="contact.email"
            rules="required|email"
            type="email"
            label="E-mail"
          ></validated-text-field>
          <validated-text-area
            v-model="contact.issue"
            label="Issue"
            rows="2"
            hint="'Please describe the feature you were trying to use and what happened.'"
            :outlined="false"
            rules="required"
          ></validated-text-area>
          <v-row
            ><v-col
              ><v-btn type="submit" :disabled="invalid" color="primary"
                >Send</v-btn
              >
              <v-btn class="ml-5" @click="handleCancel"
                >Cancel</v-btn
              ></v-col
            ></v-row
          >
        </v-sheet>
      </form></v-container
    >
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedTextArea from "@/components/Fields/ValidatedTextArea";
import api from "../administration/users/_api";
export default {
  components: {
    "validation-observer": ValidationObserver,
    "validated-text-field": ValidatedTextField,
    "validated-text-area": ValidatedTextArea,
  },
  data: () => ({
    contact: {},
    appName: process.env.VUE_APP_BRANDING,
  }),

  mounted() {
    this.clearErrors();
  },

  created() {
    this.contact = {
      name: null,
      email: null,
      issue: null,
      subject: "technical support issue",
    };
  },

  methods: {
    async clearErrors() {
      requestAnimationFrame(async () => {
        await this.$refs.obs.reset();
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    
    async submit() {
      let formValid = await this.$refs.obs.validate();
      console.log(formValid);
      if (formValid) {
        this.$toast.show(
          "Message sent.  Energy 360 support will be contacting you shortly.",
          null,
          "success"
        );
        await this.sendIssueEmail();
        this.$router.go(-1);
      }
    },

    async sendIssueEmail() {
      try {
        await api.sendIssueEmail(this.contact);
      } catch (error) {
        console.log(error);
      }
    },

    handleCancel() {
      this.$router.push({ name: "PortfoliosRoute" });
    },
  },
};
</script>